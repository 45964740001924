import { createTheme } from '@mui/material/styles';

export function theme(isDark: boolean) {
	return createTheme({
		palette: {
			mode: isDark ? 'dark' : 'light',
			primary: {
				main: isDark ? '#fff' : '#000',
				light: isDark ? '#f5f5f5' : '#333',
				dark: isDark ? '#e0e0e0' : '#000',
				contrastText: isDark ? '#000' : '#fff',
			},
			secondary: {
				main: isDark ? '#666' : '#666',
				light: isDark ? '#848484' : '#848484',
				dark: isDark ? '#404040' : '#404040',
				contrastText: isDark ? '#fff' : '#000',
			},
			background: {
				default: isDark ? '#000' : '#fff',
				paper: isDark ? '#111' : '#fff',
			},
			text: {
				primary: isDark ? '#fff' : '#000',
				secondary: isDark ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.87)',
				disabled: isDark ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.6)',
			},
			divider: isDark ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
			error: {
				main: '#f44336',
			},
			warning: {
				main: '#ff9800',
			},
			info: {
				main: '#2196f3',
			},
			success: {
				main: '#4caf50',
			},
			grey: {
				50: '#fafafa',
				100: '#f5f5f5',
				200: '#eeeeee',
				300: '#e0e0e0',
				400: '#bdbdbd',
				500: '#9e9e9e',
				600: '#757575',
				700: '#616161',
				800: '#424242',
				900: '#212121',
				A100: '#d5d5d5',
				A200: '#aaaaaa',
				A400: '#303030',
				A700: '#616161',
			},
		},
		typography: {
			fontFamily:
				'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
			h1: {
				fontSize: '2.5rem',
				fontWeight: 700,
				letterSpacing: '-0.02em',
			},
			h2: {
				fontSize: '2rem',
				fontWeight: 700,
				letterSpacing: '-0.01em',
			},
			h3: {
				fontSize: '1.5rem',
				fontWeight: 600,
			},
			h4: {
				fontSize: '1.25rem',
				fontWeight: 600,
			},
			h5: {
				fontSize: '1.125rem',
				fontWeight: 600,
			},
			h6: {
				fontSize: '1rem',
				fontWeight: 600,
			},
			body1: {
				fontSize: '1rem',
				lineHeight: 1.5,
			},
			body2: {
				fontSize: '0.875rem',
				lineHeight: 1.5,
			},
			button: {
				textTransform: 'none',
				fontWeight: 500,
			},
		},
		shape: {
			borderRadius: 8,
		},
		components: {
			MuiButton: {
				styleOverrides: {
					root: {
						borderRadius: 8,
						textTransform: 'none',
						fontWeight: 500,
					},
					contained: {
						boxShadow: 'none',
						'&:hover': {
							boxShadow: 'none',
						},
					},
					outlined: {
						borderColor: isDark ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
					},
				},
			},
			MuiPaper: {
				styleOverrides: {
					root: {
						backgroundImage: 'none',
						backgroundColor: isDark ? '#111' : '#fff',
						boxShadow: isDark ? 'none' : '0px 2px 4px rgba(0, 0, 0, 0.1)',
					},
				},
			},
			MuiCard: {
				styleOverrides: {
					root: {
						borderRadius: 12,
						border: `1px solid ${isDark ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)'}`,
					},
				},
			},
			MuiAppBar: {
				styleOverrides: {
					root: {
						backgroundColor: isDark ? '#000' : '#fff',
						boxShadow: 'none',
						borderBottom: `1px solid ${
							isDark ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)'
						}`,
					},
				},
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						borderBottom: `1px solid ${
							isDark ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)'
						}`,
					},
				},
			},
			MuiSwitch: {
				styleOverrides: {
					root: {
						padding: 8,
					},
					track: {
						backgroundColor: isDark ? '#333' : '#eaeaea',
						opacity: 1,
					},
				},
			},
		},
	});
}
export default theme;
