import React, { useEffect, useState } from 'react';
import {
	Button,
	Container,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Paper,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Divider,
	Switch,
	CircularProgress,
} from '@mui/material';
import axios from 'axios';
import { SirenDataI } from '../../utils/consts';
import { useDispatch, useSelector } from 'react-redux';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { Home as HomeIcon, Map as MapIcon, ExitToApp as ExitToAppIcon } from '@mui/icons-material';
import Box from '@mui/system/Box';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../utils/routing/routes';
import { set } from 'mongoose';
import { logout } from '../../actions/register';
import { io } from 'socket.io-client';
import { makeStyles } from '@mui/styles';

const drawerWidth = 240;
const socket = io('https://cap.simplem.in');

export const StyledSwitch = styled(Switch)(({ theme }) => ({
	width: 42,
	height: 26,
	padding: 0,
	'& .MuiSwitch-switchBase': {
		padding: 1,
		'&.Mui-checked': {
			transform: 'translateX(16px)',
			'& + .MuiSwitch-track': {
				background: 'linear-gradient(90deg, #6C63FF, #3A3DFF)', // Modern gradient
				opacity: 1,
			},
		},
	},
	'& .MuiSwitch-thumb': {
		boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
		width: 24,
		height: 24,
		backgroundColor: '#F5F5F5', // Neutral thumb color
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	'& .MuiSwitch-track': {
		borderRadius: 26 / 2,
		backgroundColor: '#B0BEC5', // Light gray for off state
		opacity: 1,
	},
}));

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});
const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	variants: [
		{
			props: ({ open }) => open,
			style: {
				...openedMixin(theme),
				'& .MuiDrawer-paper': openedMixin(theme),
			},
		},
		{
			props: ({ open }) => !open,
			style: {
				...closedMixin(theme),
				'& .MuiDrawer-paper': closedMixin(theme),
			},
		},
	],
}));

const SidebarListItemText = styled(ListItemText)(({ theme }) => ({
	'& .MuiListItemText-primary': {
		...theme.typography.body1,
	},
	'& .MuiListItemText-secondary': {
		...theme.typography.body1,
	},
}));

const SidebarListItemIcon = styled(ListItemIcon)(({ theme }) => ({
	color: theme.palette.text.primary,
}));

const Dashboard = () => {
	// State
	const [sirens, setSirens] = useState<SirenDataI[]>([]);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showIndex, setShowIndex] = useState<number | null>(0);
	const userName = useSelector((state: any) => state.register.name);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	useEffect(() => {
		getSirens();
		socket.on('connect', () => console.log('Connected'));
		socket.on('siren-acked', data => {
			const { sirenId, running } = data;
			setSirens(sirenDataCopy => {
				const sirenCopy = [...sirenDataCopy];
				sirenCopy.forEach(sirenDat => {
					if (sirenDat.id == sirenId) {
						setLoading(false);
						sirenDat.playing = running;
					}
				});
				return sirenCopy;
			});
		});
		socket.on('connection-manager-push', data => {
			const { sirenId, isOnline } = data;
			setSirens(sirenDataCopy => {
				const sirenCopy = [...sirenDataCopy];
				sirenCopy.forEach(sirenDat => {
					if (sirenDat.id == sirenId) {
						sirenDat.isOnline = isOnline;
					}
				});
				return sirenCopy;
			});
		});
	}, []);
	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(!open);
	};

	const getSirens = async () => {
		try {
			const response = await axios.get(`/api/siren/all`);
			setSirens(response.data);
		} catch (error) {
			console.error('Error fetching sirens:', error);
		}
	};

	const sendSignal = (
		sirenId: string,
		type: string,
		value: any,
		alarmType: string,
		gapAudio: number,
		language: string
	) => {
		setLoading(true);
		if (type === 'message' && value.length > 0) {
			socket.emit('siren-control', {
				sirenId,
				action: value,
				alertType: alarmType,
				gapAudio,
				language,
				connType: 'any',
			});
		} else if (type === 'switch') {
			socket.emit('siren-control', {
				sirenId,
				action: value ? 'on' : 'off',
				alertType: alarmType,
				gapAudio,
				language,
				connType: 'any',
			});
		}
	};

	return (
		<Box style={{ display: 'flex' }}>
			<Drawer
				onPointerEnter={handleDrawerOpen}
				onPointerLeave={handleDrawerClose}
				variant='permanent'
				open={open}
			>
				<DrawerHeader>
					<img src='/logo.png' alt='Logo' style={{ width: '100%' }} />
					{/* <Typography variant='h6'>Decibel Company</Typography> */}
				</DrawerHeader>
				<Divider />
				<List>
					<ListItem button key='Dashboard' onClick={() => navigate(ROUTES.DASHBOARD)}>
						<SidebarListItemIcon>
							<HomeIcon />
						</SidebarListItemIcon>
						<SidebarListItemText primary='Dashboard' />
					</ListItem>
					<ListItem button key='Map' onClick={() => navigate(ROUTES.HOME)}>
						<SidebarListItemIcon>
							<MapIcon />
						</SidebarListItemIcon>
						<SidebarListItemText primary='Map' />
					</ListItem>
				</List>
				<Divider />
				<List style={{ position: 'absolute', bottom: '0' }}>
					<ListItem button key='Logout' onClick={() => dispatch(logout())}>
						<SidebarListItemIcon>
							<ExitToAppIcon />
						</SidebarListItemIcon>
						<SidebarListItemText primary='Logout' />
					</ListItem>
				</List>
			</Drawer>

			{showIndex === 0 && (
				<Container maxWidth='lg' style={{ marginTop: '40px' }}>
					{/* Welcome Message */}
					<Typography variant='h4' gutterBottom>
						Welcome {userName}!
					</Typography>

					{/* Siren Table */}
					<TableContainer component={Paper} style={{ marginTop: '20px', marginBottom: '20px' }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>ID</TableCell>
									<TableCell>Name</TableCell>
									<TableCell>Color</TableCell>
									<TableCell>Action</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{sirens.map(siren => (
									<TableRow key={siren.id}>
										<TableCell>{siren.id}</TableCell>
										<TableCell>{siren.name}</TableCell>
										<TableCell>
											<div
												style={{
													backgroundColor: siren.color,
													height: '20px',
													width: '20px',
													borderRadius: '50%',
												}}
											></div>
										</TableCell>
										<TableCell>
											<StyledSwitch
												checked={siren.playing}
												onChange={e =>
													sendSignal(siren.id, 'switch', e.target.checked, 'warning', 10, 'hi')
												}
												disabled={loading}
												icon={
													loading ? (
														<CircularProgress size={24} color='secondary' thickness={6} />
													) : (
														<Box
															sx={{
																height: 24,
																width: 24,
																borderRadius: '50%',
																backgroundColor: '#E0E0E0',
															}}
														/>
													)
												}
												checkedIcon={
													loading ? (
														<CircularProgress size={24} color='secondary' thickness={6} />
													) : (
														<Box
															sx={{
																height: 24,
																width: 24,
																borderRadius: '50%',
																backgroundColor: '#E0E0E0',
															}}
														/>
													)
												}
											/>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					{/* Map View Button */}
					<Button variant='contained' color='primary' onClick={() => navigate(ROUTES.HOME)}>
						Go to Map View
					</Button>
				</Container>
			)}
		</Box>
	);
};

export default Dashboard;
