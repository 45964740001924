import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Link } from 'react-router-dom';

import { login } from '../../actions/register';
import { Loading } from '../elements/Loading';
import { RootState } from '../../store';
import { ROUTES } from '../../utils/routing/routes';
import { Form, Field, Formik } from 'formik';
import { object, string } from 'yup';

import Grid from '@mui/material/Grid';
import Copyright from '../elements/copyright';
import { MAX_PASSWORD_LENGTH } from '../../utils/consts';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { Container, IconButton, InputAdornment, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

const Card = styled(MuiCard)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignSelf: 'center',
	width: '100%',
	padding: theme.spacing(4),
	gap: theme.spacing(2),
	margin: 'auto',
	[theme.breakpoints.up('sm')]: {
		maxWidth: '450px',
	},
	boxShadow:
		'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
	...theme.applyStyles('dark', {
		boxShadow:
			'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
	}),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
	height: 'calc((1 - var(--template-frame-height, 0)) * 100dvh)',
	//minHeight: '100%',
	padding: theme.spacing(2),
	[theme.breakpoints.up('sm')]: {
		padding: theme.spacing(4),
	},
	'&::before': {
		content: '""',
		display: 'block',
		position: 'absolute',
		zIndex: -1,
		inset: 0,
		backgroundImage: 'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
		backgroundRepeat: 'no-repeat',
		...theme.applyStyles('dark', {
			backgroundImage: 'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
		}),
	},
}));

const AuthCard = styled(Card)(({ theme }) => {
	return {
		gap: 16,
		transition: 'all 100ms ease',
		backgroundColor: 'hsl(220, 35%, 97%)',
		borderRadius: 8,
		boxShadow: 'none',
		...theme.applyStyles('dark', {
			backgroundColor: 'gray',
		}),
		variants: [
			{
				props: {
					variant: 'outlined',
				},
				style: {
					boxShadow: 'none',
					background: 'hsl(0, 0%, 100%)',
					...theme.applyStyles('dark', {
						background: 'hsl(220, 30%, 6%)',
					}),
				},
			},
		],
	};
});

const Login = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [passwordError, setPasswordError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [emailErrorMessage, setEmailErrorMessage] = useState('');
	const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
	const [showPassword, setShowPassword] = useState(false);

	const isAuthenticated = useSelector<RootState, boolean>(state => state.register.isAuthenticated);
	const loading = useSelector<RootState, boolean>(state => state.register.loading);
	const regf = useSelector<RootState, boolean>(state => state.register);
	console.log(regf);
	const dispatch = useDispatch();
	if (loading) {
		return <Loading />;
	}

	if (isAuthenticated) {
		return <Navigate to={ROUTES.DASHBOARD} />;
	}

	const validateInputs = () => {
		let isValid = true;

		if (!email || !/\S+@\S+\.\S+/.test(email)) {
			setEmailError(true);
			setEmailErrorMessage('Please enter a valid email address.');
			isValid = false;
		} else {
			setEmailError(false);
			setEmailErrorMessage('');
		}

		if (!password || password.length < 6) {
			setPasswordError(true);
			setPasswordErrorMessage('Password must be at least 6 characters long.');
			isValid = false;
		} else {
			setPasswordError(false);
			setPasswordErrorMessage('');
		}
		if (isValid) {
			dispatch(login({ email: email, password: password }));
		}
		return isValid;
	};

	return (
		<Container>
			<SignInContainer direction='column' justifyContent='space-between'>
				<AuthCard variant='outlined'>
					<Typography
						component='h1'
						variant='h4'
						sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
					>
						Sign in
					</Typography>
					<Box
						component='form'
						sx={{
							display: 'flex',
							flexDirection: 'column',
							width: '100%',
							gap: 2,
						}}
					>
						<FormControl>
							<FormLabel htmlFor='email'>Email</FormLabel>
							<TextField
								error={emailError}
								helperText={emailErrorMessage}
								id='email'
								type='email'
								name='email'
								placeholder='your@email.com'
								autoComplete='email'
								autoFocus
								required
								fullWidth
								variant='outlined'
								color={emailError ? 'error' : 'primary'}
								onChange={e => setEmail(e.target.value)}
							/>
						</FormControl>
						<FormControl>
							<FormLabel htmlFor='password'>Password</FormLabel>
							<TextField
								error={passwordError}
								helperText={passwordErrorMessage}
								name='password'
								placeholder='•••••••'
								type={showPassword ? 'text' : 'password'}
								id='password'
								autoComplete='current-password'
								autoFocus
								required
								fullWidth
								variant='outlined'
								color={passwordError ? 'error' : 'primary'}
								onChange={e => setPassword(e.target.value)}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton
												aria-label='toggle password visibility'
												onClick={() => setShowPassword(!showPassword)}
												edge='end'
											>
												{!showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</FormControl>
						{/* <FormControlLabel
							control={<Checkbox value='remember' color='primary' />}
							label='Remember me'
						/> */}
						{/* <ForgotPassword open={open} handleClose={handleClose} /> */}
						<Button fullWidth variant='contained' onClick={validateInputs}>
							{loading ? 'Signing In' : 'Sign In'}
						</Button>
						{/* <Link
							component='button'
							type='button'
							onClick={() => navigate(BASE_ROUTES.FORGOT_PASSWORD)}
							variant='body2'
							sx={{ alignSelf: 'center' }}
						>
							Forgot your password?
						</Link> */}
					</Box>
					{/* <Divider>or</Divider> */}
					{/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
						<Typography sx={{ textAlign: 'center' }}>
							Don&apos;t have an account?{' '}
							<Link href='email:support@example.com' variant='body2' sx={{ alignSelf: 'center' }}>
								Reach out to us!
							</Link>
						</Typography>
					</Box> */}
				</AuthCard>
			</SignInContainer>
		</Container>
	);
};

export default Login;
